import { type LinkSearchListItemData } from '@atlaskit/link-picker';
import { type ActivityItem, type Container } from '@atlassian/recent-work-client';

import { type QuickSearchResult } from './types';
import { getIconFromContentType, getIconFromIconUrl } from './ui';

/**
 * Filters array to a set of unique items given a predicate/comparator function
 * @param arr Initial array of items
 * @param comparator Predicate function
 * @returns Array of filtered / unique items
 */
export function filterUniqueItems<T>(
	arr: Array<T>,
	comparator?: (firstItem: T, secondItem: T) => boolean,
): Array<T> {
	return arr.filter((firstItem, index, self) => {
		return (
			self.findIndex((secondItem) =>
				comparator ? comparator(firstItem, secondItem) : firstItem === secondItem,
			) === index
		);
	});
}

export const mapActivityClientResultToLinkSearchItemData = ({
	object,
	containers,
	timestamp,
}: ActivityItem): LinkSearchListItemData => {
	let iconUrl = object.icon?.value || '';
	if (object.icon?.type === 'EMOJI') {
		iconUrl = object.icon.fallback || '';
	}

	return {
		objectId: object.id,
		name: object.name || '',
		container: getContainerName(containers),
		url: object.url,
		...getIconFromIconUrl(iconUrl),
		lastViewedDate: timestamp ? new Date(timestamp) : undefined,
		prefetch: true,
		meta: {
			source: 'recent-work',
		},
	};
};

export const filterItems = (
	items: Array<LinkSearchListItemData> | undefined,
	searchTerm: string,
): Array<LinkSearchListItemData> => {
	if (!searchTerm || !items) {
		return [];
	}

	return items.filter((item) => {
		return item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
	});
};

export const mapSearchProviderResultToLinkSearchItemData = ({
	objectId,
	container,
	title,
	contentType,
	url,
	updatedTimestamp,
	iconUrl,
}: QuickSearchResult): LinkSearchListItemData => ({
	objectId,
	name: title,
	container,
	url,
	...// Use the icon url from the quick search result if it exists but still resolve the altText,
	// which return defaultAlt if no icon is found. Otherwise find an icon by looking up the content type
	(iconUrl
		? {
				icon: iconUrl,
				iconAlt: getIconFromContentType(contentType).iconAlt,
			}
		: getIconFromContentType(contentType)),
	lastUpdatedDate: updatedTimestamp ? new Date(updatedTimestamp) : undefined,
	prefetch: false,
	meta: {
		source: 'xp-search',
	},
});

const getContainerName = (containers: Array<Container>): string => {
	for (let i = containers.length - 1; i >= 0; --i) {
		const containerName = containers[i].name;
		if (containerName) {
			return containerName;
		}
	}
	return '';
};

/** No results returned for townsquare (Atlas) if `platform` is used in filter
 * https://atlassian.slack.com/archives/CGZMUTRUL/p1684729057252109?thread_ts=1684727786.451419&cid=CGZMUTRUL
 */

/**
 * Generate the ARI for particular product to send to the recent items API.
 */
function generateProductSpecificAri(product: string, cloudId: string): string {
	if (product === 'townsquare') {
		/** No results returned for townsquare (Atlas) if `platform` is used in filter
		 * https://atlassian.slack.com/archives/CGZMUTRUL/p1684729057252109?thread_ts=1684727786.451419&cid=CGZMUTRUL
		 */
		return `ari:cloud:townsquare::site/${cloudId}`;
	}
	return `ari:cloud:platform::site/${cloudId}`;
}

/**
 * Generate an array of ARI's for a list of products. Generally there is a single product, so the output will be a single
 * ARI string.
 * If multiple arrays have the same ARI they will be deduplicated
 */
export function getArisForProducts(products: string[] | undefined, cloudId: string): string[] {
	const defaultAri = `ari:cloud:platform::site/${cloudId}`;
	if (products === undefined) {
		return [defaultAri];
	}
	const allAris: string[] = products.map((product) => generateProductSpecificAri(product, cloudId));
	// Deduplicate
	return allAris.filter((value, index) => allAris.indexOf(value) === index);
}
