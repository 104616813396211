import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	hyperlinkIconIssueLabel: {
		id: 'fabric.linkPicker.headingLink.hyperlinkIconIssueLabel',
		defaultMessage: 'Issue',
		description: 'icon label for a jira issue',
	},
	hyperlinkIconBugLabel: {
		id: 'fabric.linkPicker.headingLink.hyperlinkIconBugLabel',
		defaultMessage: 'Bug',
		description: 'icon label for a jira bug',
	},
	hyperlinkIconStoryLabel: {
		id: 'fabric.linkPicker.headingLink.hyperlinkIconStoryLabel',
		defaultMessage: 'Story',
		description: 'icon label for a jira story',
	},
	hyperlinkIconTaskLabel: {
		id: 'fabric.linkPicker.headingLink.hyperlinkIconTaskLabel',
		defaultMessage: 'Task',
		description: 'icon label for a jira task',
	},
	hyperlinkIconEpicLabel: {
		id: 'fabric.linkPicker.headingLink.hyperlinkIconEpicLabel',
		defaultMessage: 'Epic',
		description: 'icon label for a jira epic',
	},
	hyperlinkIconDashboardLabel: {
		id: 'fabric.linkPicker.headingLink.hyperlinkIconDashboardLabel',
		defaultMessage: 'Dashboard',
		description: 'icon label for a jira dashboard',
	},
	hyperlinkIconBoardLabel: {
		id: 'fabric.linkPicker.headingLink.hyperlinkIconBoardLabel',
		defaultMessage: 'Board',
		description: 'icon label for a jira board',
	},
	hyperlinkIconProjectLabel: {
		id: 'fabric.linkPicker.headingLink.hyperlinkIconProjectLabel',
		defaultMessage: 'Task',
		description: 'icon label for a jira project',
	},
	hyperlinkIconPlanLabel: {
		id: 'fabric.linkPicker.headingLink.hyperlinkIconPlanLabel',
		defaultMessage: 'Plan',
		description: 'icon label for a jira plan',
	},
	hyperlinkIconFilterLabel: {
		id: 'fabric.linkPicker.headingLink.hyperlinkIconFilterLabel',
		defaultMessage: 'Filter',
		description: 'icon label for a jira filter',
	},
	hyperlinkIconPageLabel: {
		id: 'fabric.linkPicker.headingLink.hyperlinkIconPageLabel',
		defaultMessage: 'Page',
		description: 'icon label for a confluence page',
	},
	hyperlinkIconBlogLabel: {
		id: 'fabric.linkPicker.headingLink.hyperlinkIconBlogLabel',
		defaultMessage: 'Blog',
		description: 'icon label for a confluence blog',
	},
	hyperlinkIconWhiteboardLabel: {
		id: 'fabric.linkPicker.headingLink.hyperlinkIconWhiteboardLabel',
		defaultMessage: 'Whiteboard',
		description: 'icon label for a confluence whiteboard',
	},
	hyperlinkIconDatabaseLabel: {
		id: 'fabric.linkPicker.headingLink.hyperlinkIconDatabaseLabel',
		defaultMessage: 'Database',
		description: 'icon label for a confluence database',
	},
	hyperlinkIconEmbedLabel: {
		id: 'fabric.linkPicker.headingLink.hyperlinkIconEmbedLabel',
		defaultMessage: 'Smart Link',
		description: 'icon label for a confluence smart link',
	},
	hyperlinkIconLoomLabel: {
		id: 'fabric.linkPicker.headingLink.hyperlinkIconLoomLabel',
		defaultMessage: 'Loom',
		description: 'icon label for a confluence loom',
	},
	story: {
		id: 'fabric.linkPicker.story',
		defaultMessage: 'Story',
		description: 'Alt text for Story',
	},
	epic: {
		id: 'fabric.linkPicker.epic',
		defaultMessage: 'Epic',
		description: 'Alt text for Epic',
	},
	task: {
		id: 'fabric.linkPicker.task',
		defaultMessage: 'Task',
		description: 'Alt text for Task',
	},
	bug: {
		id: 'fabric.linkPicker.bug',
		defaultMessage: 'Bug',
		description: 'Alt text for Bug',
	},
	subTask: {
		id: 'fabric.linkPicker.subTask',
		defaultMessage: 'Sub-task',
		description: 'Alt text for Sub-task',
	},
	improvement: {
		id: 'fabric.linkPicker.improvement',
		defaultMessage: 'Improvement',
		description: 'Alt text for Improvement',
	},
	defaultAltText: {
		id: 'fabric.linkPicker.defaultAltText',
		defaultMessage: 'List item',
		description: 'Default alt text for ListItem image',
	},
});
