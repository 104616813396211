// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';

import { token } from '@atlaskit/tokens';

const HEIGHT = 120;

// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const noResultsSVGStyles = css({
	height: `${HEIGHT}px`,
	margin: `0 auto ${token('space.300', '24px')}`,
	display: 'block',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const noResultsSVGStyles_handle = css({
	mixBlendMode: 'multiply',
});
