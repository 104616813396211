// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';

import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const emptyStateWrapperStyles = css({
	marginBottom: `${token('space.negative.200', '-16px')}`,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	p: {
		// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
		margin: '0',
	},
});
